<template>
  <v-container fluid>
    <PageHeaderSlot :showBottomLeftAndRightRow="true" :showBackButton="true" :isLoading="submitLoading">
    </PageHeaderSlot>
    <v-row class="mt-6">
      <v-col cols="12">
        <h3>{{ $validate.DataValid(id) ? '員工詳細' : '新增員工' }}</h3>
      </v-col>
      <v-col cols="12">
        <v-form ref="staff-form" @submit.prevent="formSubmit">
          <v-card class="mb-8">
            <v-card-title>
              <h5>員工資料</h5>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6" cols="12">
                  <FormControl inputType="string" :inputValue.sync="name" label="員工姓名" :required="true" />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="select"
                    :inputValue.sync="staff_type"
                    label="員工類別"
                    :options="staffTypeList"
                    :required="true"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="number"
                    :inputValue.sync="phone"
                    label="電話"
                    :required="true"
                    :inputNumberOnly="true"
                    :customChecking="[
                      v => {
                        return this.$validate.hkMobileValid(v)
                      },
                    ]"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl inputType="datePicker" :inputValue.sync="entry_date" label="入職日期" :required="true" />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl inputType="email" :inputValue.sync="email" label="電郵" :required="true" />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="select"
                    :inputValue.sync="staff_status"
                    label="狀態"
                    :options="staffStatusList"
                    :required="true"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl inputType="string" :inputValue.sync="address" label="地址" :required="true" />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl inputType="string" :inputValue.sync="position" label="職位" :required="true" />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="timePicker"
                    :inputValue.sync="start_working_hour"
                    label="上班時間"
                    :required="true"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="timePicker"
                    :inputValue.sync="end_working_hour"
                    label="下班時間"
                    :required="true"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="number"
                    :inputValue.sync="annual_leave"
                    label="年假"
                    :required="true"
                    :inputFloatingOnly="true"
                    :validateFloating="true"
                    :customChecking="[
                      v => {
                        return maxDateChecking(v)
                      },
                    ]"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="datePicker"
                    :inputValue.sync="annual_start_date"
                    label="年期開始計算日"
                    :required="true"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="number"
                    :inputValue.sync="annual_comp_day"
                    label="本年度補假"
                    :required="true"
                    :inputFloatingOnly="true"
                    :validateFloating="true"
                    :customChecking="[
                      v => {
                        return maxDateChecking(v)
                      },
                    ]"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="number"
                    :inputValue.sync="sick_leave"
                    label="每月病假"
                    :required="true"
                    :inputFloatingOnly="true"
                    :validateFloating="true"
                    :customChecking="[
                      v => {
                        return maxDateChecking(v, 30)
                      },
                    ]"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="number"
                    :inputValue.sync="salary"
                    label="薪金"
                    :required="true"
                    :inputFloatingOnly="true"
                    :validateFloating="true"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl inputType="number" :inputValue.sync="remark" label="備註" />
                </v-col>
                <v-col cols="12">
                  <h3>員工合約</h3>
                </v-col>
                <v-col md="6" cols="12">
                  <ImageUploader
                    :singleImage="true"
                    :imgList.sync="contractFile"
                    :uploadText="'上載員工合約'"
                    :acceptFormat="'imageWithPDF'"
                    :disableUpload="submitLoading"
                  ></ImageUploader>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-8">
            <v-card-title>
              <h5>銀行帳號</h5>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6" cols="12">
                  <FormControl inputType="string" :inputValue.sync="bank_name" label="銀行名稱" />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl inputType="string" :inputValue.sync="bank_account" label="帳戶號碼" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-8">
            <v-card-title>
              <h5>{{ $validate.DataValid(id) ? '更改密碼' : '設定密碼' }}</h5>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="string"
                    :inputValue.sync="password"
                    label="密碼"
                    :required="!$validate.DataValid(id)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="submitLoading">
            儲存
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormControl from '@/components/form/FormControl'
import ImageUploader from '@/components/ImageUploader.vue'

export default {
  name: 'AdminStaffInfoForm',
  components: {
    FormControl,
    ImageUploader,
  },
  data() {
    return {
      submitLoading: false,
      submitSuccess: false,
      name: '',
      phone: '',
      email: '',
      address: '',
      staff_type: '',
      entry_date: '',
      staff_status: '',
      position: '',
      annual_leave: '',
      annual_start_date: '',
      sick_leave: '',
      start_working_hour: '',
      end_working_hour: '',
      annual_comp_day: '',
      salary: '',
      remark: '',
      contractFile: [],
      bank_name: '',
      bank_account: '',
      password: '',
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    staffTypeList() {
      return this.$staffTypeList
    },
    staffStatusList() {
      return this.$staffStatusList
    },
  },
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    maxDateChecking(v, day = 365) {
      const inputDay = parseFloat(v)
      const maxDate = parseInt(day)

      const mod = (inputDay % 1) * 10

      if (mod !== 0 && mod !== 5) {
        return `假期只可輸入整數或小數位為0.5的日數`
      }

      if (inputDay > maxDate) {
        return `假期不可多於${maxDate}日`
      }

      return true
    },

    async getStaffDetail() {
      let staffError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_staff_by_id', payload)
        this.$func.log('-----Get Staff Detail-----')
        this.$func.log(result)

        this.name = result.name
        this.phone = result.phone
        this.email = result.email
        this.address = result.address
        this.staff_type = result.staff_type
        this.entry_date = result.entry_date
        this.staff_status = result.staff_status
        this.position = result.position
        this.annual_leave = result.annual_leave
        this.annual_start_date = result.annual_start_date
        this.sick_leave = result.sick_leave
        this.salary = result.salary
        this.remark = result.remark
        this.contract = result.contract
        this.bank_name = result.bank_name
        this.bank_account = result.bank_account
        this.start_working_hour = result.start_working_hour
        this.end_working_hour = result.end_working_hour
        this.annual_comp_day = result.annual_comp_day
        this.contractFile = result.contract_file

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get Staff Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          staffError = true
          message = '沒有權限'
        } else {
          staffError = true
        }
      }

      if (staffError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'adminStaff',
        })
      }
    },

    async formSubmit() {
      if (!this.submitLoading) {
        this.submitLoading = true

        const isValid = this.$refs['staff-form'].validate()

        const workingHourValid = this.start_working_hour < this.end_working_hour

        if (!isValid) {
          this.submitLoading = false

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        if (!workingHourValid) {
          this.submitLoading = false

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '上班時間不可早於下班時間',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        try {
          const payload = {
            data_type: 'staff_data',
            status: 'active',
            data_status: 'active',
            verify_token: this.getAdminToken(),
            name: this.name,
            email: this.email,
            phone: this.phone,
            position: this.position,
            entry_date: this.entry_date,
            staff_type: this.staff_type,
            staff_status: this.staff_status,
            address: this.address,
            annual_leave: parseFloat(this.annual_leave),
            annual_start_date: this.annual_start_date,
            sick_leave: parseFloat(this.sick_leave),
            salary: parseFloat(this.salary),
            remark: this.remark,
            contract_file: this.contractFile,
            bank_name: this.bank_name,
            bank_account: this.bank_account,
            annual_comp_day: parseFloat(this.annual_comp_day),
            start_working_hour: this.start_working_hour,
            end_working_hour: this.end_working_hour,
          }

          let key = ''

          if (this.$validate.DataValid(this.id)) {
            key = 'cms_update_staff'

            if (this.$validate.DataValid(this.password.trim())) {
              payload.password = this.password.trim()
            }

            payload.id = parseInt(this.id)
          } else {
            key = 'cms_add_staff'

            payload.password = this.password
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('--- update staff data ---')
          this.$func.log(result)

          this.submitSuccess = true

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'adminStaff' },
          })
          this.setShowDialog(true)
        } catch (error) {
          this.$func.log('--- update staff data fail ---')
          this.$func.log(error)

          let msg = '儲存失敗'

          if (error.data === 'admin verification fail') {
            msg = ''
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else if (error.data === 'staff not found') {
            msg = '員工不存在'
          } else if (error.data === 'email format not match') {
            msg = '電郵格式不正確'
          } else if (error.data === 'email duplicate') {
            msg = '電郵已被使用'
          } else if (error.data === 'email cannot be empty') {
            msg = '電郵不可為空'
          } else if (error.data === 'password cannot be empty') {
            msg = '密碼不可為空'
          }

          this.submitLoading = false
          this.submitSuccess = false
          this.setDialogMessage({
            message: msg,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      if (!this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      } else {
        this.getStaffDetail()
      }
    }
  },
  async beforeDestroy() {
    let removeList = []
    if (this.$store.getters.removedImageList.length > 0) {
      removeList = removeList.concat(this.$store.getters.removedImageList)
    }

   /*  if (!this.submitSuccess) {
      removeList = removeList.concat(this.medical_certificate)
    } */

    if (removeList.length > 0) {
      const removeImage = await this.$XHR.api('cms_remove_media', {
        verify_token: this.getAdminToken(),
        remove_image_list: this.$store.getters.removedImageList,
      })

      this.$func.log('--- remove image success ---')
      this.$func.log(removeImage)
    }
  },
}
</script>
